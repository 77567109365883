/*
 * © Copyright 2014 – 2023 Open Text or one of its affiliates.
 *
 * The only warranties for products and services of Open Text and its affiliates and licensors ("Open Text") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Open Text shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 * */

import React from 'react';
import { OTPVerifier } from '../../../ux/ux';
import t from '../../../i18n/locale-keys';

export const EnrollmentStatus = {
    OK: 'OK',
    MORE_DATA: 'MORE_DATA',
    FAILED: 'FAILED'
};

export const EnrollmentReasons = {
    OTP_WRONG_PASSWORD: 'OTP_WRONG_PASSWORD',
    OTP_WAITING_PASSWORD: 'OTP_WAITING_PASSWORD'
};

export default function withOTPVerificationWrapper(WrappedComponent) {
    class OTPHOC extends React.Component {
        state = {
            enrollResponse: null,
            otpcode: ''
        };

        handleEnroll = (data) => {
            const formData = this.state.otpcode ? this.state.otpcode : data;
            return this.props.doEnrollWithBeginProcess(formData)
                .then((response) => {
                    if (response.status === EnrollmentStatus.OK) {
                        return Promise.resolve(response);
                    }
                    if (response.status === EnrollmentStatus.MORE_DATA) {
                        this.setState({ enrollResponse: response });
                        return Promise.reject(t.needOTPVerification());
                    }
                    if (response.status === EnrollmentStatus.FAILED
                        && response.reason === EnrollmentReasons.OTP_WRONG_PASSWORD) {
                        this.setState({ enrollResponse: response, otpcode: '' });
                        return Promise.reject(t.wrongOTPEntered());
                    }
                    else {
                        throw response.msg;
                    }
                });
        };

        resend = (event) => {
            this.props.onSubmit(event);
        };

        continueEnrollment = (event, data) => {
            const otp = { answer: data.otpcode };
            this.setState({ otpcode: otp }, () => this.props.onSubmit(event));
        };

        getVerificaitionElements = () => {
            if (this.state.enrollResponse &&
                (this.state.enrollResponse.reason === EnrollmentReasons.OTP_WAITING_PASSWORD ||
                    this.state.enrollResponse.reason === EnrollmentReasons.OTP_WRONG_PASSWORD)) {
                return (<OTPVerifier continueEnrollment={this.continueEnrollment}
                        resendOTP={this.resend}
                        enrollResponse={this.state.enrollResponse}
                        />);
            }
        };

        checkVerificationStatus = () => {
            if ((this.state.enrollResponse && this.state.enrollResponse.status === EnrollmentStatus.OK)
                || (!this.state.enrollResponse)) {
                return false;
            }
           return true;
        };

        render() {
            const { forwardedRef, ...rest } = this.props;

            WrappedComponent.displayName = `(${WrappedComponent.displayName})`;
            return (
                <div>
                    <WrappedComponent ref={forwardedRef} {...rest} handleEnroll={this.handleEnroll}
                        otpElements={this.getVerificaitionElements}
                        checkVerificationStatus={this.checkVerificationStatus}
                    />
                </div>
            );
        }
    }
    return React.forwardRef((props, ref) => {
        return <OTPHOC {...props} forwardedRef={ref} />;
    });
}
