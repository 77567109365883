/*
 * © Copyright 2014 – 2023 Open Text or one of its affiliates.
 *
 * The only warranties for products and services of Open Text and its affiliates and licensors ("Open Text") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Open Text shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */
import React from 'react';
import { connect } from 'react-redux';
import { SSO_DEFAULT_LOGOUT_REDIRECT } from '../../actions/navigation.actions';
import { parseQueryString } from '../../utils/url-functions';
import { logoutUser } from '../../actions/authentication.actions';

class SSOLogout extends React.PureComponent {
    logOutSSO = () => {
        const redirectUri = parseQueryString(window.location.search)['redirect_uri'] || SSO_DEFAULT_LOGOUT_REDIRECT;
        this.props.logoutUser().then((success) => {
            window.location.href = redirectUri;
        }).catch(err => console.error('Error', err));
    };

    componentDidMount() {
        this.logOutSSO();
    }

    render() {
        const ssoStyle = { marginTop: '60px', textAlign: 'center'};
        return <div style ={ssoStyle} />;
    }
}

const mapDispatchToProps = {
    logoutUser
};

export default connect(null, mapDispatchToProps)(SSOLogout);
