/*
 * © Copyright 2014 – 2023 Open Text or one of its affiliates.
 *
 * The only warranties for products and services of Open Text and its affiliates and licensors ("Open Text") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Open Text shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 * */

import PropTypes from 'prop-types';
import React from 'react';
import TextField from '../components/TextField';
import t from '../i18n/locale-keys';
import { generateFormChangeHandler } from '../utils/form-handler';
import './OTPVerifier.scss';
import { EnrollmentReasons } from '../components/enrollment/method-authenticators/OTPVerificationWrapper';
import { StatusIndicator, STATUS_TYPE } from './ux';

class OTPVerifier extends React.PureComponent {
    constructor(props) {
        super(props);
        generateFormChangeHandler(this, { otpcode: '' });
    }

    handleVerification = (event) => {
        this.props.continueEnrollment(event, this.state.form);
    };

    handleResend = (event) => {
        this.setState({ form: { otpcode: '' } }, () => {
            this.props.resendOTP(event);
        });
    };

    render() {
        const { enrollResponse } = this.props;
        return (
        <React.Fragment>
            {enrollResponse && enrollResponse.reason === EnrollmentReasons.OTP_WAITING_PASSWORD &&
                <div className="otp-verifier">
                    <StatusIndicator type={STATUS_TYPE.INFO}>
                        <span>{t.enterOTPText()}</span>
                    </StatusIndicator>
                    <div className="otp-input-wrapper">
                        <TextField
                            disabled={this.props.readonlyMode}
                            id="OTP_Input_Field"
                            label={t.otpVerifierLabel()}
                            onChange={this.handleChange}
                            value={this.state.form.otpcode}
                            autoFocus
                            name="otpcode"
                            autoComplete={'off'}
                        />
                        <button className="ias-button otp-button"
                            disabled={this.state.form.otpcode.length <= 0}
                            onClick={(event) => this.handleVerification(event)}
                        >
                            {t.otpVerifyBtnTitle()}
                        </button>
                    </div>
                    <label>{enrollResponse.msg}</label>
                </div>

            }
            {enrollResponse && enrollResponse.reason === EnrollmentReasons.OTP_WRONG_PASSWORD &&
                <button className="ias-button" type="button"
                    onClick={(event) => this.handleResend(event)}
                >
                    {t.resendOTP()}
                </button>
            }
        </React.Fragment>);
    }
}

OTPVerifier.propTypes = {
    continueEnrollment: PropTypes.func
};

export default OTPVerifier;
