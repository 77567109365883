/*
 * © Copyright 2014 – 2023 Open Text or one of its affiliates.
 *
 * The only warranties for products and services of Open Text and its affiliates and licensors ("Open Text") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Open Text shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import './Languages.scss';
import React from 'react';
import {connect} from 'react-redux';
import {goBack} from '../../actions/navigation.actions';
import t from '../../i18n/locale-keys';
import {LANGUAGES, setDirection} from '../../i18n/i18n';
import i18next from 'i18next';

class Languages extends React.PureComponent {
    handleClose = () => {
        this.props.goBack();
    };

    selectLanguage = (languageKey) => {
        // Change language using i18next, not Redux. App.js listens for language change & will update Redux state.
        i18next.changeLanguage(languageKey)
            .then(() => {
                // Wait to set HTML direction until translations load
                setDirection(languageKey);
            });
    };

    renderLanguageElements() {
        return Object.keys(LANGUAGES).map((key) => {
            const language = LANGUAGES[key];

            const handleClick = () => this.selectLanguage(key);
            const handleKeyPress = (event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                    handleClick();
                }
            };
            const isCurrentLanguage = (key === this.props.language);
            const languageClass = isCurrentLanguage ? 'current' : null;
            const icons = isCurrentLanguage ? <i className="ias-icon ias-icon-check_thick" /> : null;

            return (
                <tr className={languageClass} key={key} onClick={handleClick} tabIndex="0" onKeyPress={handleKeyPress}>
                    <td title={language.title} id={'language-' + key}>
                        {icons}
                        <bdi>{language.name}</bdi>
                    </td>
                </tr>
            );
        });
    }

    render() {
        const languageElements = this.renderLanguageElements();

        return (
            <div className="ias-content-padding">
                <div className="ias-header">
                    <h2>{t.languageSelect()}</h2>
                    <span className="ias-fill" />
                    <button
                        className="ias-button ias-icon-button"
                        id="Close_Languages_Button"
                        onClick={this.handleClose}
                        title={t.buttonClose()}
                        type="button"
                    >
                        <i className="ias-icon ias-icon-close_thin" />
                    </button>
                </div>

                <table className="ias-table languages-list">
                    <tbody>
                        {languageElements}
                    </tbody>
                </table>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const i18nextLanguage = state.locale.language;
    let language = null;

    if (LANGUAGES[i18nextLanguage]) {
        language = i18nextLanguage;
    }
    else if (i18nextLanguage.indexOf('-') !== -1) {    // Show 'en' if 'en-US' is not available, etc.
        language = i18nextLanguage.split('-')[0];
    }

    return {language};
};
const mapDispatchToProps = { goBack };

export default connect(mapStateToProps, mapDispatchToProps)(Languages);
