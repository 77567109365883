/*
 * © Copyright 2014 – 2023 Open Text or one of its affiliates.
 *
 * The only warranties for products and services of Open Text and its affiliates and licensors ("Open Text") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Open Text shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import browserLanguageDetector from 'i18next-browser-languagedetector';
import chainedBackend from 'i18next-chained-backend';
import fetchBackend from 'i18next-fetch-backend';
import i18next from 'i18next';
import localStorageBackend from 'i18next-localstorage-backend';

// This file loads translations using i18next.

// Set cache expiration time to 1 sec in development, 1 day in production
const CACHE_EXPIRATION_TIME = (process.env.NODE_ENV === 'development') ? 1000 : (24 * 60 * 60 * 1000);

// TODO: change load path once backend translations are ready
// TODO: delete public/locale folder once backend translations are ready

i18next
    .use(browserLanguageDetector)
    .use(chainedBackend)
    .init({
        backend: {
            backends: [
                localStorageBackend,    // Primary source of translations - local storage cache
                fetchBackend            // Secondary source of translations - HTTP fetch
            ],
            backendOptions: [
                {
                    expirationTime: CACHE_EXPIRATION_TIME
                },
                {   // eslint-disable-next-line no-template-curly-in-string
                    loadPath: './locale/${lng}.json',
                    allowMultiLoading: false
                }
            ]
        },
        detection: {
            order: ['cookie', 'localStorage', 'navigator'],
            lookupCookie: '_LOCALE_',
            lookupLocalStorage: '_LOCALE_',
            caches: ['localStorage', 'cookie'],
            cookieMinutes: Number.MAX_VALUE
        },
        fallbackLng: false,             // Because all keys are English strings
        interpolation: {prefix: '${', suffix: '}'},
        keySeparator: '##',
        nsSeparator: '::',              // To allow messages with ':'
    });

export function _(key, replace) {
    return i18next.t(key, {replace});
}

// eslint-disable-next-line no-underscore-dangle
export function _k(key, semanticKey, replace) {
    return i18next.t([semanticKey, key], replace);
}

export function loadLang() {
    if (process.env.NODE_ENV === 'production') {
        console.log('Lang has been set');
        //On Page load we have to trigger the language change, by default it's not getting triggered
        if (i18next && i18next.language) {
            i18next.changeLanguage(i18next.language)
                .then(() => {
                    // Wait to set HTML direction until translations load
                    setDirection(i18next.language);
                });
        }
    }
}

export const LANGUAGES = {
    'ar-SA': {title: 'Arabic (Saudi Arabia)', name: '(العربية) المملكة العربية السعودية'},
    'da': {title: 'Danish', name: 'Dansk'},
    'de': {title: 'German', name: 'Deutsch'},
    'en': {title: 'English', name: 'English'},
    'es': {title: 'Spanish', name: 'Español'},
    'fr': {title: 'French', name: 'Français'},
    'fr-CA': {title: 'French (Canada)', name: 'Français (Canada)'},
    'he': {title: 'Hebrew (Israel)‎', name: 'עברית (ישראל)'},
    'hu': {title: 'Hungarian', name: 'Magyar'},
    'it': {title: 'Italian', name: 'Italiano'},
    'ja': {title: 'Japanese', name: '日本語'},
    'nl': {title: 'Dutch', name: 'Nederlands'},
    'pl': {title: 'Polish', name: 'Polski'},
    'pt-BR': {title: 'Portuguese (Brazil)', name: 'Português (Brasil)'},
    'ru': {title: 'Russian', name: 'Русский'},
    'sv': {title: 'Swedish', name: 'Svenska'},
    'zh-CN': {title: 'Chinese (People\'s Republic of China)', name: '中文(中华人民共和国)'},
    'zh-TW': {title: 'Chinese (Taiwan)', name: '中文(台灣)'}
};

export function setDirection(language) {
    const dir = i18next.dir(language);
    document.body.setAttribute('dir', dir);
}
