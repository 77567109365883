/*
 * © Copyright 2014 – 2023 Open Text or one of its affiliates.
 *
 * The only warranties for products and services of Open Text and its affiliates and licensors ("Open Text") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Open Text shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import React from 'react';
import ShowHidePassword from '../../../ShowHidePassword';
import {generateFormChangeHandler} from '../../../../utils/form-handler';
import {deviceAuthSignChallenge} from '../../../../api/devices/device-auth-device.api';
import {STATUS_TYPE} from '../../../../ux/ux';
import t from '../../../../i18n/locale-keys';

class DeviceAuthTest extends React.PureComponent {
    constructor(props) {
        super(props);

        generateFormChangeHandler(this, {
            pin: ''
        });

        props.doTestLogon().then((result) => {
            this.challengeData = result;
            this.props.showStatus(t.deviceAuthSpecifyPin(), STATUS_TYPE.INFO);
        });
    }

    componentWillUnmount() {
    }

    finishTest = () => {
        const {challenge, keypairId: keypairid} = this.challengeData;
        const {pin} = this.state.form;
        this.props.registerPromise(
            deviceAuthSignChallenge({challenge, pin, keypairid})
        ).then((data) => {
            const {signature, padding, hash} = data;
            this.props.doTestLogon({signature, padding, hash});
        }).catch((errorMessage) => {
            this.props.markTestComplete(false, errorMessage);
        });
    };

    render() {
        return (
            <React.Fragment>
                <ShowHidePassword
                    autoFocus
                    name="pin"
                    onChange={this.handleChange}
                    placeholder={t.deviceAuthPin()}
                    value={this.state.form.pin}
                />
            </React.Fragment>
        );
    }
}

export default DeviceAuthTest;
