/*
 * © Copyright 2014 – 2023 Open Text or one of its affiliates.
 *
 * The only warranties for products and services of Open Text and its affiliates and licensors ("Open Text") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Open Text shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import jsonFetch from '../json-fetch';
import t from '../../i18n/locale-keys';

const WINHELLO_SERVICE_URL = 'https://127.0.0.1:8440/api/v1/wh';
const WINHELLO_ENROLL_SUFFIX = '/enroll';
const WINHELLO_LOGON_SUFFIX = '/logon';

function callWinHelloService(urlSuffix, data) {
    return jsonFetch({
        camelize: true,
        data,
        decamelize: true,
        method: 'POST',
        url: WINHELLO_SERVICE_URL + urlSuffix
    }).catch((failedResponseData) => {
        if (failedResponseData.status) {
            return Promise.reject(t.windowsHelloServiceUnavailable());
        }

        return Promise.reject(t.windowsHelloServiceError());
    }).then((data) => {
        if (data.result === 'failed') {
            return Promise.reject(t.verificationFailed());
        }

        return data;
    });
}

export const winHelloEnroll = (data) => callWinHelloService(WINHELLO_ENROLL_SUFFIX, data);
export const winHelloLogon = (data) => callWinHelloService(WINHELLO_LOGON_SUFFIX, data);
