/*
 * © Copyright 2014 – 2023 Open Text or one of its affiliates.
 *
 * The only warranties for products and services of Open Text and its affiliates and licensors ("Open Text") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Open Text shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import React from 'react';
import ShowHidePassword from '../../../ShowHidePassword';
import {generateFormChangeHandler} from '../../../../utils/form-handler';
import t from '../../../../i18n/locale-keys';

// TODO: add special error HTML and link URL to form (see auth.js:1334 in previous UI).
class LDAPPasswordTest extends React.PureComponent {
    constructor(props) {
        super(props);

        generateFormChangeHandler(this, {
            answer: ''
        });
    }

    render() {
        return (
            <React.Fragment>
                <ShowHidePassword
                    autoFocus
                    name="answer"
                    onChange={this.handleChange}
                    placeholder={t.passwordLabel()}
                    value={this.state.form.answer}
                />
            </React.Fragment>
        );
    }
}

export default LDAPPasswordTest;
