/*
 * © Copyright 2014 – 2023 Open Text or one of its affiliates.
 *
 * The only warranties for products and services of Open Text and its affiliates and licensors ("Open Text") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Open Text shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

// Authentication
export const AUTHENTICATE_USER_REQUEST = 'AUTHENTICATE_USER_REQUEST';
export const AUTHENTICATE_USER_SUCCESS = 'AUTHENTICATE_USER_SUCCESS';
export const AUTHENTICATE_USER_FAILURE = 'AUTHENTICATE_USER_FAILURE';

export const CLEAR_USER_INFO = 'CLEAR_USER_INFO';

export const LOGIN_CHAINS_REQUEST = 'LOGIN_CHAINS_REQUEST';
export const LOGIN_CHAINS_SUCCESS = 'LOGIN_CHAINS_SUCCESS';
export const LOGIN_CHAINS_FAILURE = 'LOGIN_CHAINS_FAILURE';

export const LOAD_LOGIN_SESSION_INFO = 'LOAD_LOGIN_SESSION_INFO';

export const SET_MULTI_TENANCY_STATUS = 'SET_MULTI_TENANCY_STATUS';
export const SET_EFFECTIVE_POLICY = 'SET_EFFECTIVE_POLICY';

export const UPDATE_LOGIN_CHAIN = 'UPDATE_LOGIN_CHAIN';
export const UPDATE_LOGIN_FORM_DATA = 'UPDATE_LOGIN_FORM_DATA';
export const UPDATE_USERNAME = 'UPDATE_USERNAME';

export const FETCH_TENANT_NAME = 'FETCH_TENANT_NAME';
export const REDIRECT_TO_OSP_LOGON = 'REDIRECT_TO_OSP_LOGON';

// Language
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

// Navigation
export const BEGIN_SIGN_OUT = 'BEGIN_SIGN_OUT';
export const HIDE_NAVIGATION_DIALOG = 'HIDE_NAVIGATION_DIALOG';
export const FINISH_SIGN_OUT = 'FINISH_SIGN_OUT';
export const SHOW_NAVIGATION_DIALOG = 'SHOW_NAVIGATION_DIALOG';

// Methods Display
export const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';

export const FETCH_ENROLLABLE_CHAINS_REQUEST = 'FETCH_ENROLLABLE_CHAINS_REQUEST';
export const FETCH_ENROLLABLE_CHAINS_SUCCESS = 'FETCH_ENROLLABLE_CHAINS_SUCCESS';
export const FETCH_ENROLLABLE_CHAINS_FAILURE = 'FETCH_ENROLLABLE_CHAINS_FAILURE';

export const FETCH_METHOD_TITLES_REQUEST = 'FETCH_METHOD_TITLES_REQUEST';
export const FETCH_METHOD_TITLES_SUCCESS = 'FETCH_METHOD_TITLES_SUCCESS';
export const FETCH_METHOD_TITLES_FAILURE = 'FETCH_METHOD_TITLES_FAILURE';

export const FETCH_POLICIES_REQUEST = 'FETCH_POLICIES_REQUEST';
export const FETCH_POLICIES_SUCCESS = 'FETCH_POLICIES_SUCCESS';
export const FETCH_POLICIES_FAILURE = 'FETCH_POLICIES_FAILURE';

export const FETCH_REQUIRED_METHODS_REQUEST = 'FETCH_REQUIRED_METHODS_REQUEST';
export const FETCH_REQUIRED_METHODS_SUCCESS = 'FETCH_REQUIRED_METHODS_SUCCESS';
export const FETCH_REQUIRED_METHODS_FAILURE = 'FETCH_REQUIRED_METHODS_FAILURE';

export const FETCH_TEMPLATES_REQUEST = 'FETCH_TEMPLATES_REQUEST';
export const FETCH_TEMPLATES_SUCCESS = 'FETCH_TEMPLATES_SUCCESS';
export const FETCH_TEMPLATES_FAILURE = 'FETCH_TEMPLATES_FAILURE';

export const INDEX_DATA = 'INDEX_DATA';
