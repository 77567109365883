/*
 * © Copyright 2014 – 2023 Open Text or one of its affiliates.
 *
 * The only warranties for products and services of Open Text and its affiliates and licensors ("Open Text") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Open Text shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import {abortPendingNavigation, continuePendingNavigation} from '../actions/navigation.actions';
import {connect} from 'react-redux';
import Dialog from '../ux/Dialog';
import PropTypes from 'prop-types';
import React from 'react';

const NavigationDialog = (props) => {
    return (
        <Dialog
            onCancel={props.abortPendingNavigation}
            onClose={props.continuePendingNavigation}
            open={props.showNavigationDialog}
            title={
                <div className="ias-dialog-label">
                    {props.navigationDialogTitle}
                </div>
            }
        >
            <p>{props.navigationDialogMessage}</p>
        </Dialog>
    );
};

NavigationDialog.propTypes = {
    showNavigationDialog: PropTypes.bool,
    navigationDialogMessage: PropTypes.string,
    navigationDialogTitle: PropTypes.string
};

const mapStateToProps = ({ navigation }) => (navigation);
const mapDispatchToProps = { abortPendingNavigation, continuePendingNavigation };

export default connect(mapStateToProps, mapDispatchToProps)(NavigationDialog);
