/*
 * © Copyright 2014 – 2023 Open Text or one of its affiliates.
 *
 * The only warranties for products and services of Open Text and its affiliates and licensors ("Open Text") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Open Text shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import React from 'react';
import CommonCardHandler from '../../../../api/devices/common-card-devices.api';

class CardTest extends React.PureComponent {
    constructor(props) {
        super(props);

        this.props.setTestButtonAvailability(false);

        this.commonCardHandler = new CommonCardHandler(CommonCardHandler.CARD_SERVICE_URL, props.showStatus,
            props.registerPromise);

        this.commonCardHandler.getStatus({
            onCard: (data) => this.props.doTestLogon(data)
        });
    }

    componentWillUnmount() {
        this.commonCardHandler.abortCardPromise();
    }

    render() {
        return null;
    }
}

export default CardTest;
