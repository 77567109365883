/*
 * © Copyright 2014 – 2023 Open Text or one of its affiliates.
 *
 * The only warranties for products and services of Open Text and its affiliates and licensors ("Open Text") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Open Text shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import React from 'react';
import Authenticator from '../Authenticator';
import {generateFormChangeHandler} from '../../../utils/form-handler';
import TextField from '../../TextField';
import t from '../../../i18n/locale-keys';
import {createToast, STATUS_TYPE} from '../../../ux/ux';
import withOTPVerificationWrapper from './OTPVerificationWrapper';

class SMSOTPMethod extends React.PureComponent {
    constructor(props) {
        super(props);

        const {isEnrolled, data} = props.template;
        let mobilePhone = '';
        if (isEnrolled && data) {
            mobilePhone = data.mobilePhone || '';
        }
        const initialOtherState = { defaultRecipient: null };
        generateFormChangeHandler(this, { mobilePhone }, initialOtherState);

        this.props.getDefaultRecipient(this.props.template.methodId).then(({defaultRecipient}) => {
            this.setState({defaultRecipient});
        });
    }

    authenticationInfoChanged() {
        return this.state.dataDirty;
    }

    authenticationInfoSavable() {
        return !this.props.template.isEnrolled || this.authenticationInfoChanged();
    }

    checkOverrideInput() {
        const userMobilePhone = this.state.defaultRecipient || t.recipientUnknown();
        // Prevent saving if no number is provided
        if (this.state.form.mobilePhone === '' && userMobilePhone === t.recipientUnknown()) {
            return this.props.btnDisable = true;
        }
        return this.props.checkVerificationStatus() ? this.props.btnDisable = true : this.props.btnDisable = false;
    }

    finishEnroll() {
        const {mobilePhone} = this.state.form;

        const formData = mobilePhone.length ? { mobilePhone } : {};
        return this.props.handleEnroll(formData);
    }

    renderOverrideElements() {
        return (
            <React.Fragment>
                <div>
                    <label>{t.mobilePhoneOverride()}</label>
                </div>
                <TextField
                    disabled={this.props.readonlyMode}
                    id="Mobile_Input_Field"
                    label={t.mobilePhoneOverrideLabel()}
                    name="mobilePhone"
                    onChange={this.handleChange}
                    value={this.state.form.mobilePhone}
                />
            </React.Fragment>
        );
    }

    render() {
        const userMobilePhone = this.state.defaultRecipient || t.recipientUnknown();
        const overrideElements = this.props.policies.SMSOTPMethod.data.allowOverrideRecipient
            ? this.renderOverrideElements() : null;
        this.checkOverrideInput();

        return (
            <Authenticator
                description={t.smsOtpMethodDescription()}
                {...this.props}
            >
                <div>
                    <label>{t.mobilePhonePosessive()}</label>
                    <span className="directory-data">{userMobilePhone}</span>
                </div>
                <div>
                    <label>{t.directoryFrom()}</label>
                </div>
                {overrideElements}
                {this.props.otpElements && this.props.otpElements()}
            </Authenticator>
        );
    }
}

export default withOTPVerificationWrapper(SMSOTPMethod);
