/*
 * © Copyright 2014 – 2023 Open Text or one of its affiliates.
 *
 * The only warranties for products and services of Open Text and its affiliates and licensors ("Open Text") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Open Text shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import React from 'react';
import ShowHidePassword from '../../../ShowHidePassword';
import {generateFormChangeHandler} from '../../../../utils/form-handler';
import {pkiSignChallenge} from '../../../../api/devices/pki-device.api';
import {STATUS_TYPE} from '../../../../ux/ux';
import CommonCardHandler from '../../../../api/devices/common-card-devices.api';
import t from '../../../../i18n/locale-keys';

class PKITest extends React.PureComponent {
    constructor(props) {
        super(props);

        this.pkiHandler = new CommonCardHandler(CommonCardHandler.PKI_SERVICE_URL, props.showStatus,
            props.registerPromise);

        generateFormChangeHandler(this, {
            pin: ''
        });

        this.pkiHandler.getStatus({
            onCard: () => {
                props.doTestLogon().then((result) => {
                    this.challengeData = result;
                    this.props.showStatus(t.pkiSpecifyPin(), STATUS_TYPE.INFO);
                });
            }
        });
    }

    componentWillUnmount() {
        this.pkiHandler.abortCardPromise();
    }

    finishTest = () => {
        const {challenge, certSerial, keypairId: keypairid} = this.challengeData;
        const {pin} = this.state.form;
        this.props.registerPromise(
            pkiSignChallenge({challenge, pin, keypairid, certSerial})
        ).then((data) => {
            const {signature, padding, hash, readerid, keypairid, cardCert, subject, cardUid} = data;
            this.props.doTestLogon({readerid, signature, padding, hash, cardCert, cardUid, subject}, true);
        }).catch((errorMessage) => {
            this.props.markTestComplete(false, errorMessage);
        });
    };

    render() {
        return (
            <React.Fragment>
                <ShowHidePassword
                    autoFocus
                    name="pin"
                    onChange={this.handleChange}
                    placeholder={t.pkiPin()}
                    value={this.state.form.pin}
                />
            </React.Fragment>
        );
    }
}

export default PKITest;
