/*
 * © Copyright 2014 – 2023 Open Text or one of its affiliates.
 *
 * The only warranties for products and services of Open Text and its affiliates and licensors ("Open Text") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Open Text shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import './App.scss';
import {abortPendingNavigation, continuePendingNavigation} from '../actions/navigation.actions';
import AvailableMethods from './methods-pages/AvailableMethods';
import RequiredMethods from './methods-pages/RequiredMethods'
import ChainEnrollment from '../components/enrollment/ChainEnrollment';
import {connect} from 'react-redux';
import {ToastContainer, LoadingIndicator} from '../ux/ux';
import EnrolledMethods from './methods-pages/EnrolledMethods';
import Header from './secondary-layout/Header';
import history from '../history';
import Languages from '../components/languages/Languages';
import Login from './login/Login';
import SSOLogout from './sso-logout/SSOLogout';
import MethodEnrollment from '../components/enrollment/MethodEnrollment';
import PrivateRoute from './PrivateRoute';
import React, {Suspense} from 'react';
// Use the  HistoryRouter rather than BrowserRouter so we can provide our own history object.
// See https://reactrouter.com/en/v6.3.0/api#unstable_historyrouter
import {Route, Routes, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import NavigationDialog from './NavigationDialog';
import i18next from 'i18next';
import {changeLanguage} from '../actions/locale.actions';
import {AUTHENTICATION_STATES} from '../reducers/authentication.reducer';
import t from '../i18n/locale-keys';
//cache the image for suspense fallback ,LoadingIndicator.
import loading from './loading.png';
import { loadLang } from '../i18n/i18n';

//This is FOR DEVELOPMENT ALONE, IN PROD THIS WILL BE SKIPPED.
//THIS WILL HELP DEVELOPEMNT FASTER,Without setting Aucore & OSP.
let DevLogin = null;
if (process.env.NODE_ENV === 'development') {
    DevLogin = React.lazy(() => import('./login/DevLogin'));
}

class App extends React.PureComponent {
    componentDidMount() {
        // Listen for language changes so we can update Redux store so translations reload.
        // Because Languages is a separate page, nearly all of the components reload translations on page load only.
        // The Header and Languages components are the two components that use the Redux store to reload translations.
        i18next.on('languageChanged', this.onLanguageChange);
        loadLang();
    }

    componentWillUnmount() {
        i18next.off('languageChanged', this.onLanguageChange);
    }

    onLanguageChange = (language) => {
        this.props.changeLanguage(language);
        document.body.setAttribute('dir', i18next.dir(language));
    };

    render() {
        const isLoginPage = this.props.auth.status !== AUTHENTICATION_STATES.LOGGED_IN;
        const contentStyles = isLoginPage ? 'scrollable-content ias-login-screen' : 'scrollable-content';
        return (
            <HistoryRouter history={history} basename={history.basename}>
                {!isLoginPage ? <Header /> : null}
                <div className={contentStyles}>
                    <main>
                        <Suspense fallback={<LoadingIndicator src={loading} message={t.loading()} />}>
                            <Routes>
                                <Route path="/"
                                    element={
                                        <PrivateRoute path="/">
                                            <EnrolledMethods />
                                        </PrivateRoute>
                                    }
                                />
                                <Route path="/available/:type"
                                    element={
                                        <PrivateRoute>
                                            <AvailableMethods />
                                        </PrivateRoute>
                                    }
                                />
                                <Route path="/enrollment"
                                    element={
                                        <PrivateRoute>
                                            <RequiredMethods />
                                        </PrivateRoute>
                                    }
                                />
                                <Route path="/chains/:chainUri/:methodUri/:templateUri"
                                    element={
                                        <PrivateRoute>
                                            <ChainEnrollment />
                                        </PrivateRoute>
                                    }
                                />
                                <Route path="/chains/:chainUri/:methodUri"
                                    element={
                                        <PrivateRoute>
                                            <ChainEnrollment />
                                        </PrivateRoute>
                                    }
                                />
                                <Route path="/chains/:chainUri"
                                    element={
                                        <PrivateRoute>
                                            <ChainEnrollment />
                                        </PrivateRoute>
                                    }
                                />
                                <Route path="/:methodUri/:templateType"
                                    element={
                                        <PrivateRoute>
                                            <MethodEnrollment />
                                        </PrivateRoute>
                                    }
                                />
                                <Route path="/:methodUri"
                                    element={
                                        <PrivateRoute>
                                            <MethodEnrollment />
                                        </PrivateRoute>
                                    }
                                />
                                
                                <Route path="/sso_logout"
                                    element={
                                        <PrivateRoute>
                                            <SSOLogout />
                                        </PrivateRoute>
                                    }
                                />
                                <Route path="/languages" element={<Languages />} />
                                {process.env.NODE_ENV === 'development' ?
                                    <Route path="/login" element={<DevLogin />} /> :
                                    <Route path="/login" element={<Login />} />
                                }
                            </Routes>
                        </Suspense>
                    </main>
                </div>

                <ToastContainer />
                <NavigationDialog />
            </HistoryRouter>
        );
    }
}

const mapDispatchToProps = { abortPendingNavigation, changeLanguage, continuePendingNavigation };
const mapStateToProps = state => {
    return { auth: state.authentication };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
