/*
 * © Copyright 2014 – 2023 Open Text or one of its affiliates.
 *
 * The only warranties for products and services of Open Text and its affiliates and licensors ("Open Text") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Open Text shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import React from 'react';
import Authenticator from '../Authenticator';
import ShowHidePassword from '../../ShowHidePassword';
import {Accordion} from '../../../ux/ux';
import {generateFormChangeHandler} from '../../../utils/form-handler';
import QRCodeComponent from '../../../ux/QRCodeComponent';
import TextField from '../../TextField';
import t from '../../../i18n/locale-keys';
import {EnrollmentStatus} from "./OTPVerificationWrapper";

class TOTPMethod extends React.PureComponent {
    constructor(props) {
        super(props);

        const policy = this.props.policies.TOTPMethod;
        const isBase32Secret = policy.data.useKeyUriFormatQr;
        const period = policy.data.otpPeriod;
        const serviceName = t.productName();
        const accountName = this.props.authentication.userEmail || this.props.authentication.username;
        const otp = '';
        const secret = '';
        const serial = '';

        const initialFormState = {
            isBase32Secret,
            serviceName,
            accountName,
            otp,
            period,
            secret,
            serial
        };

        generateFormChangeHandler(
            this,
            initialFormState,
            {
                qrdata: '',
                qrsecret: null
            }
        );
    }

    showQRCode = false;

    componentDidMount() {
        //getNewSecret() only if the template is not already enrolled.
        if (!this.props.template.isEnrolled) {
            this.getNewSecret(this.state.form.isBase32Secret, this.props.policies.TOTPMethod.data.allowManualEnrollment);
        }
    }

    authenticationInfoChanged() {
        return this.state.dataDirty;
    }

    finishEnroll() {
        const {isBase32Secret, otp, period, secret, serial, serviceName, accountName} = this.state.form;
        const {qrsecret} = this.state;

        let formData;
        if (serial !== '') {
            formData = {serial, otp};
        } else if (qrsecret) {
            formData = {
                isBase32Secret,
                secret: qrsecret,
                serviceName: serviceName,
                accountName: accountName
            };
        } else {
            formData = {period, secret};
        }

        return this.props.doEnrollWithBeginProcess(formData)
            .then((response) => {
                if (response.status !== EnrollmentStatus.FAILED) {
                    return Promise.resolve(response);
                } else {
                    throw response.msg;
                }
            });
    }

    getManualSecret(response, state) {
        const { secret } = response;
        const newFormState = { ...state.form, secret };
        return {
            form: newFormState,
            dataDirty: true
        };
    }

    getNewSecret(isBase32Secret,isManualSecret) {
        return this.props.registerPromise(
                this.props.getTotpQrCode(
                    isBase32Secret,
                    this.state.form.service_name,
                    this.state.form.account_name)
            ).then(response => {
                this.setState((state) => {
                    if (isManualSecret) { return this.getManualSecret(response, state); }
                    const { secret, period, qrdata } = response;
                    const newOtherState = {
                        qrdata,
                        qrsecret: secret
                    };

                const newFormState = {
                    ...state.form,
                    period: isManualSecret ? state.form.period : period,
                    secret
                };

                    return {
                        ...newOtherState,
                        form: newFormState,
                        dataDirty: true
                    };
                });
            });
    }

    updateQRCode = (event) => {
        this.handleChange(event);
        setTimeout(
            () => {
                this.getNewSecret(this.state.form.isBase32Secret, false);
            }
        );
    }

    handleCheckboxClick = (event) => {
        const checked = event.target.checked;
        this.handleChange(event);
        if (this.state.qrdata) {
            this.getNewSecret(checked, false);
        }
    };

    handleQRClick = () => {
        this.showQRCode = true;
        this.getNewSecret(this.state.form.isBase32Secret, false);
    };

    renderEnrollElements() {
        const {isEnrolled, data} = this.props.template;

        const totpDisplay = this.props.policies.TOTPMethod.data.totpDisplay;
        const displayTOTP = totpDisplay === "display_totp_only" || totpDisplay === "display_both";
        const displayOauth = totpDisplay === "display_oath_token_only" || totpDisplay === "display_both";
        const startOpen = displayOauth && !displayTOTP;

        // If enrolled, return only token serial number, and only when populated
        if (isEnrolled) {
            if (data && data.serial) {
                return (
                    <div className="ias-input-container">
                        <label>{t.oathSerialNumber()}<span>{data.serial}</span></label>
                    </div>
                );
            }

            return null;
        }

        const qrElement = this.state.qrdata && this.showQRCode ?
            <QRCodeComponent key={this.state.qrdata} text={this.state.qrdata} /> : null;

        let manualEnrollmentSection = null;
        if (this.props.policies.TOTPMethod.data.allowManualEnrollment) {
            manualEnrollmentSection = (
                <Accordion title={t.totpManual()}>
                    <ShowHidePassword
                        disabled={this.props.readonlyMode}
                        id="Secret_Field"
                        name="secret"
                        label={t.secretLabel()}
                        onChange={this.handleChange}
                        value={this.state.form.secret}
                    />
                    <div className="ias-input-container ias-inline">
                        <div>
                            <input
                                checked={this.state.form.isBase32Secret}
                                disabled={this.props.readonlyMode}
                                id="Base32_Secret_Field"
                                name="isBase32Secret"
                                onChange={this.handleCheckboxClick}
                                type="checkbox"
                            />
                            <label htmlFor="Base32_Secret_Field">{t.totpUseBase32()}</label>
                        </div>
                    </div>
                    <TextField
                        disabled={this.props.readonlyMode}
                        id="Period_Field"
                        label={t.totpPeriod()}
                        name="period"
                        onChange={this.handleChange}
                        value={this.state.form.period}
                    />
                </Accordion>
            );
        }

        return (
            <React.Fragment>
                {!isEnrolled && (displayOauth || totpDisplay === "display_both") && (
                    <Accordion title={t.oathToken()} startOpen={startOpen}>
                        <TextField
                            autoComplete={"new-password"}
                            disabled={this.props.readonlyMode}
                            id="Token_Serial_Field"
                            label={t.oathSerialNumber()}
                            name="serial"
                            onChange={this.handleChange}
                            value={this.state.form.serial}
                        />
                        <ShowHidePassword
                            disabled={this.props.readonlyMode}
                            id="Token_Verify_Field"
                            name="otp"
                            label={t.oneTimePassword()}
                            onChange={this.handleChange}
                            value={this.state.form.otp}
                        />
                    </Accordion>
                )}
                {displayTOTP &&
                    <>
                        <div className="authenticator-section">
                            <button
                                disabled={this.props.readonlyMode}
                                id="QR_Code_Button"
                                type="button"
                                className="ias-button"
                                onClick={this.handleQRClick}
                            >
                                {t.buttonGetQRCode()}
                            </button>
                        </div>
                        {qrElement}
                    </>
                }
                {manualEnrollmentSection}
            </React.Fragment>
        );
    }
    getEnrollmentInstructions = () => {
        const totpDisplay = this.props.policies.TOTPMethod.data.totpDisplay;
        const displayOauth = totpDisplay === 'display_oath_token_only';

        const manualOption = this.props.policies.TOTPMethod.data.allowManualEnrollment
            ? (totpDisplay === 'display_both' ? <li>{t.totpEnrollOptionsManual()}</li> : <span>{t.totpEnrollOptionsManual()}</span>)
            : null;

        const displayBothInstructions = (
            <div className="description">
                {t.totpEnrollOptionsLabel()}
                <ul>
                    <li>{t.totpEnrollOptionsSerial()}</li>
                    <li>{t.totpEnrollOptionsSmartphone()}</li>
                    {manualOption}
                </ul>
            </div>
        );

        const displaySingleInstruction = (
            <div>
                <p className="description">
                    {displayOauth ? t.totpEnrollOptionsSerial() : t.totpEnrollOptionsSmartphone()}
                </p>
                {manualOption}
            </div>
        );

        return totpDisplay === 'display_both' ? displayBothInstructions : displaySingleInstruction;
    }

    render() {
        const isEnrolled = this.props.template.isEnrolled;
        let enrollInstructions = !isEnrolled ? this.getEnrollmentInstructions() : null;

        const enrollElements = this.renderEnrollElements();

        let googleAuthenticatorOptions = null;

        if (this.state.form.isBase32Secret && !isEnrolled) {

            googleAuthenticatorOptions = (
                <React.Fragment>
                    <TextField
                        disabled={this.props.readonlyMode}
                        id="Service_Name"
                        label={t.otpServiceName()}
                        name="service_name"
                        onChange={this.updateQRCode}
                        value={this.state.form.service_name}
                    />
                    <TextField
                        disabled={this.props.readonlyMode}
                        id="Account_Name"
                        label={t.otpAccountName()}
                        name="account_name"
                        onChange={this.updateQRCode}
                        value={this.state.form.account_name}
                    />
                </React.Fragment>
            );
        }

        return (
            <Authenticator
                description={t.totpMethodDescription()}
                {...this.props}
            >
                {googleAuthenticatorOptions}
                {enrollInstructions}
                {enrollElements}
            </Authenticator>
        );
    }
}

export default TOTPMethod;
