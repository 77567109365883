/*
 * © Copyright 2014 – 2023 Open Text or one of its affiliates.
 *
 * The only warranties for products and services of Open Text and its affiliates and licensors ("Open Text") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Open Text shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

export const methodIds = {
    BANKID: 'BANKID:1',
    BLUETOOTH: 'BLUETOOTH:2',
    BLUETOOTH_ESEC: 'BLUETOOTH_ESEC:1',
    CARD: 'CARD:1',
    CERTIFICATION: 'CERTIFICATION:1',
    DEVICE_AUTH: 'DEVICE_AUTH:1',
    EMAIL_OTP: 'EMAIL_OTP:1',
    EMERG_PASSWORD: 'EMERG_PASSWORD:1',
    FACE: 'FACE:1',
    FIDO2: 'FIDO2:1',
    FINGER: 'FINGER:1',
    FLEX_OTP: 'FLEX_OTP:1',
    HANIS: 'HANIS:1',
    HOTP: 'HOTP:1',
    LDAP_PASSWORD: 'LDAP_PASSWORD:1',
    NEMID: 'NEMID:1',
    OAUTH2: 'OAUTH2:1',
    PASSWORD: 'PASSWORD:1',
    PKI: 'PKI:1',
    RADIUS: 'RADIUS:1',
    SAML_SP: 'SAML_SP:1',
    SECQUEST: 'SECQUEST:1',
    SMARTPHONE: 'SMARTPHONE:1',
    SMS_OTP: 'SMS_OTP:1',
    SWISSCOM: 'SWISSCOM:1',
    TOTP: 'TOTP:1',
    TOUCHID: 'TOUCHID:1',
    U2F: 'U2F:1',
    VOICE: 'VOICE:1',
    VOICE_OTP: 'VOICE_OTP:1',
    WEBAUTH: 'WEBAUTH:1',
    WINHELLO: 'WINHELLO:1',
    OOB: 'OOB:1'
};

export const unenrollableMethods = [ methodIds.EMERG_PASSWORD, methodIds.OAUTH2];

export const autocreatedMethods = [
    methodIds.FLEX_OTP,
    methodIds.LDAP_PASSWORD,
    methodIds.NEMID,
    methodIds.RADIUS,
    methodIds.SAML_SP,
    methodIds.SWISSCOM,
];

// Store icons for the methods. Also store methodTitles, but this is not used in application except on login page
// temporarily until OSP replaces it. (This is why the titles aren't localized.)
export const methods = {
    [methodIds.BANKID]: { methodTitle: 'BankID', icon: 'bankid' },
    [methodIds.BLUETOOTH]: { methodTitle: 'Bluetooth', icon: 'bluetooth' },
    [methodIds.BLUETOOTH_ESEC]: { methodTitle: 'Bluetooth eSec', icon: 'bluetooth_esec' },
    [methodIds.CARD]: { methodTitle: 'Card', icon: 'card_chip' },
    [methodIds.CERTIFICATION]: { methodTitle: 'Certification', icon: 'certification' },
    [methodIds.DEVICE_AUTH]: { methodTitle: 'Device Authentication', icon: 'device_monitor_thin' },
    [methodIds.EMAIL_OTP]: { methodTitle: 'Email OTP', icon: 'email_thin' },
    [methodIds.EMERG_PASSWORD]: { methodTitle: 'Emergency Password', icon: 'password_emergency' },
    [methodIds.FACE]: { methodTitle: 'Facial Recognition', icon: 'facial_recognition' },
    [methodIds.FIDO2]: { methodTitle: 'FIDO2', icon: 'secure_web' },
    [methodIds.FINGER]: { methodTitle: 'Fingerprint', icon: 'fingerprint_thin' },
    [methodIds.FLEX_OTP]: { methodTitle: 'Flex OTP', icon: 'flex_OTP' },
    [methodIds.HANIS]: { methodTitle: 'HANIS', icon: 'HANIS_SouthAfrica' },
    [methodIds.HOTP]: { methodTitle: 'HOTP', icon: 'numeric_code' },
    [methodIds.LDAP_PASSWORD]: { methodTitle: 'LDAP Password', icon: 'password_ldap' },
    [methodIds.NEMID]: { methodTitle: 'Denmark National ID', icon: 'NEMID'},
    [methodIds.OAUTH2]: { methodTitle: 'Oauth2', icon: 'secure_identity' },
    [methodIds.PASSWORD]: { methodTitle: 'Password', icon: 'password_thin' },
    [methodIds.PKI]: { methodTitle: 'PKI', icon: 'pki_public_key' },
    [methodIds.RADIUS]: { methodTitle: 'Radius Client', icon: 'network' },
    [methodIds.SAML_SP]: { methodTitle: 'SAML Service Provider', icon: 'app_saml'},
    [methodIds.SECQUEST]: { methodTitle: 'Security Questions', icon: 'security_questions' },
    [methodIds.SMARTPHONE]: { methodTitle: 'Smartphone', icon: 'phone_mobile_thick' },
    [methodIds.SMS_OTP]: { methodTitle: 'SMS OTP', icon: 'comment_thick' },
    [methodIds.SWISSCOM]: { methodTitle: 'Swisscom Mobile ID', icon: 'swisscom_mobile_id' },
    [methodIds.TOTP]: { methodTitle: 'Time-based OTP (TOTP)', icon: 'time_thick' },
    [methodIds.TOUCHID]: { methodTitle: 'Touch ID', icon: 'Apple_touch_ID' },
    [methodIds.U2F]: { methodTitle: 'U2F', icon: 'usb' },
    [methodIds.VOICE]: { methodTitle: 'Voice', icon: 'phone_keypad' },
    [methodIds.VOICE_OTP]: { methodTitle: 'Voice OTP', icon: 'phone_thick' },
    [methodIds.WEBAUTH]: { methodTitle: 'Web Authentication', icon: 'world_thin' },
    [methodIds.WINHELLO]: { methodTitle: 'Windows Hello', icon: 'sys_windows ' },
    [methodIds.OOB]: { methodTitle: 'OOB UI', icon: 'out_of_band' }
};
