/*
 * © Copyright 2014 – 2023 Open Text or one of its affiliates.
 *
 * The only warranties for products and services of Open Text and its affiliates and licensors ("Open Text") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Open Text shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

// We need to access the History object in Redux to programmatically navigate throughout the application.
// See https://stackoverflow.com/a/42320249/5016547

import {createBrowserHistory } from 'history';
// Tell react-router our app root.
// Create-react-app injects the app root URL into process.env.PUBLIC_URL.
// In production, it uses the package.json "homepage" field, in development it uses "/".
const historyInstance = createBrowserHistory();
// new history lib has removed basename , we have to set it up explictily.
const basename = process.env.PUBLIC_URL;
historyInstance.basename = basename;
//history basename issue , we have to write a custom push to fix this.
//https://github.com/remix-run/history/issues/810
historyInstance.navigate = function(url, state) {
    let to = url || basename;
    if (typeof to === 'string') {
        to = basename + to;
    }
    if (typeof to === 'object' && to.pathname) {
        to.pathname = basename + to.pathname;
    }
    if (state !== undefined && state.pathname) {
        to.pathname = basename + state.pathname;
    }
    historyInstance.push(to, state);
};

export default historyInstance;
