import React from 'react';
import Authenticator from '../Authenticator';
import t from '../../../i18n/locale-keys';
import {generateFormChangeHandler} from '../../../utils/form-handler';
import {createToast, STATUS_TYPE} from '../../../ux/ux';
import ShowHidePassword from '../../ShowHidePassword';

class NemIDMethod extends React.PureComponent {
    constructor(props) {
        super(props);

        const { data, isEnrolled } = props.template;
        let ssnNumber = '';
        if (isEnrolled && data) {
            ssnNumber = data.ssnNumber || '';
        }

        const initialOtherState = { defaultRecipient: null };
        generateFormChangeHandler(this, { ssnNumber }, initialOtherState);

        this.props.getDefaultRecipient(props.template.methodId).then(({ defaultRecipient }) => {
            this.setState({ defaultRecipient });
        });
    }

    authenticationInfoChanged() {
        return this.state.dataDirty;
    }

    authenticationInfoSavable() {
        return !this.props.template.isEnrolled || this.authenticationInfoChanged();
    }

    noSSNRegistered() {
        const userSSN = this.state.defaultRecipient || t.recipientUnknown();

        return this.state.form.ssnNumber === ''
            && userSSN === t.recipientUnknown()
            && this.state.dataDirty === true;
    }

    checkOverrideInput() {
        if (this.noSSNRegistered()) {
            const description = t.nemIDNoSSNRegistered();
            createToast({type: STATUS_TYPE.ERROR, description});

            return this.props.btnDisable = true;
        }

        return this.props.btnDisable = false;
    }

    finishEnroll() {
        const { ssnNumber } = this.state.form;
        const formData = ssnNumber.length ? { ...this.state.form } : {};

        return this.props.doEnrollWithBeginProcess(formData)
            .then((response) => {
                if (response.status !== 'FAILED') {
                    return Promise.resolve(response);
                }
                else {
                    throw response.msg;
                }
            });
    }

    getPolicyData() {
        return this.props.policies.nemIDMethod.data;
    }

    render() {
        const policy = this.getPolicyData();
        const userSSN = this.state.defaultRecipient || t.recipientUnknown();
        const overrideSSN = policy.allowOverrideSsnNumber ? (
                <React.Fragment>
                    <div>
                        <label>{t.hanisOverrideInfoLabel()}</label>
                    </div>
                    <ShowHidePassword
                        disabled={this.props.readonlyMode}
                        id="Ssn_Number_Input_Field"
                        label={t.nemIDOverrideSSNLabel()}
                        name="ssnNumber"
                        onChange={this.handleChange}
                        value={this.state.form.ssnNumber}
                    />
                </React.Fragment>
            ) : null;
        this.checkOverrideInput();

        return (
            <Authenticator description={t.nemIDMethodDescription()} {...this.props}>
                <div>
                    <label>{t.nemIDSSNLabel()}</label>
                    <span className="directory-data">{userSSN}</span>
                </div>
                {overrideSSN}
            </Authenticator>
        );
    }
}

export default NemIDMethod;
