/*
 * © Copyright 2014 – 2023 Open Text or one of its affiliates.
 *
 * The only warranties for products and services of Open Text and its affiliates and licensors ("Open Text") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Open Text shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import { combineReducers } from 'redux';
import authentication from './authentication.reducer';
import locale from './locale.reducer';
import methodsDisplay from './methods-display.reducer';
import navigation from './navigation.reducer';

const rootReducer = combineReducers({
    authentication,
    locale,
    methodsDisplay,
    navigation
});

export default rootReducer;
