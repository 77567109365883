/*
 * © Copyright 2014 – 2023 Open Text or one of its affiliates.
 *
 * The only warranties for products and services of Open Text and its affiliates and licensors ("Open Text") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Open Text shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import React from 'react';
import Authenticator from '../Authenticator';
import t from '../../../i18n/locale-keys';

class SwisscomMethod extends React.PureComponent {
    authenticationInfoChanged() {
        return false;
    }

    authenticationInfoSavable() {
        return !this.props.template.isEnrolled;
    }

    finishEnroll() {
        return this.props.doEnrollWithBeginProcess()
            .then((response) => {
                if (response.status !== 'FAILED') {
                    return Promise.resolve(response);
                }
                else {
                    throw response.msg;
                }
            });
    };

    render() {
        const userMobilePhone = this.props.authentication.userMobilePhone || t.recipientUnknown();

        return (
            <Authenticator
                description={t.swisscomMethodDescription(userMobilePhone)}
                {...this.props}
            />
        );
    }
}

export default SwisscomMethod;
