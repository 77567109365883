/*
 * © Copyright 2014 – 2023 Open Text or one of its affiliates.
 *
 * The only warranties for products and services of Open Text and its affiliates and licensors ("Open Text") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Open Text shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import React from 'react';
import {STATUS_TYPE} from '../../../../ux/ux';
import {bluetoothDetectDevice, RESULT_CODES} from '../../../../api/devices/bluetooth-devices.api';
import t from '../../../../i18n/locale-keys';

const bluetoothTestVersion = {version: 1};

class BluetoothTest extends React.PureComponent {
    constructor(props) {
        super(props);

        this.props.setTestButtonAvailability(false);

        this.props.doTestLogon(bluetoothTestVersion).then((data) => {
            const {cert, nonce, version} = data;
            const deviceName = this.props?.template.data.devices?.[0].name;    // Only one device can be enrolled
            if (cert && nonce) {
                this.props.showStatus(t.bluetoothWaitingForService(), STATUS_TYPE.INFO);
                this.props.registerPromise(
                    bluetoothDetectDevice({cert, nonce, version})
                ).then((deviceServiceInitialResponse) => {
                    if (deviceServiceInitialResponse.result === RESULT_CODES.MORE_DATA) {
                        this.props.doTestLogon(deviceServiceInitialResponse).then((serverMoreDataResponse) => {
                            const {iv, message, tag} = serverMoreDataResponse;
                            this.props.showStatus(t.bluetoothTestingForDevice(deviceName), STATUS_TYPE.INFO);
                            this.props.registerPromise(
                                bluetoothDetectDevice({iv, message, tag, status: RESULT_CODES.MORE_DATA})
                            ).then((data) => {
                                const {iv, message, result, tag, version} = data;
                                const logonData = {iv, result, tag, version};
                                if (result === RESULT_CODES.BLUETOOTH_DISABLED) {
                                    return Promise.reject(t.bluetoothServiceError());
                                }
                                else if (result === RESULT_CODES.BLUETOOTH_ENABLED) {
                                    logonData.message = message;
                                }
                                this.props.doTestLogon(logonData);
                            }).catch((error) => {
                                // Different error types: .msg (server), .message (JS exception), string (application threw)
                                const message = error.msg || error.message || error;
                                this.props.markTestComplete(false, message, STATUS_TYPE.ERROR);
                            });
                        })
                    }
                    else {
                        this.props.markTestComplete(false, t.unknownError(), STATUS_TYPE.ERROR);
                    }
                }).catch((error) => {
                    // Different error types: .msg (server), .message (JS exception), string (application threw)
                    const message = error.msg || error.message || error;
                    this.props.markTestComplete(false, message, STATUS_TYPE.ERROR);
                });
            }
            else {
                this.props.markTestComplete(false, t.unknownError(), STATUS_TYPE.ERROR);
            }
        });
    }

    render() {
        return null;
    }
}

export default BluetoothTest;
