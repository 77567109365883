/*
 * © Copyright 2014 – 2023 Open Text or one of its affiliates.
 *
 * The only warranties for products and services of Open Text and its affiliates and licensors ("Open Text") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Open Text shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import './BluetoothMethod.scss';
import React from 'react';
import Authenticator from '../Authenticator';
import {STATUS_TYPE} from '../../../ux/ux';
import t from '../../../i18n/locale-keys';
import {bluetoothGetDevices} from '../../../api/devices/bluetooth-devices.api';

class BluetoothMethod extends React.PureComponent {
    state = {
        dataDirty: false,
        devices: [],
        loading: false,
        selectedDevices: []
    };

    authenticationInfoChanged() {
        return this.state.dataDirty;
    }

    componentDidMount() {
        this.getDevices();
    }

    //Filter a list of device objects based on whether the device.hash is included in a list of desired hashes
    findDevicesWithHashList(devices, hashes) {
        return devices.map(d => hashes.indexOf(d.hash) > -1 ? d : null).filter(d => d !== null);
    }

    finishEnroll() {
        const devices = this.state.selectedDevices;
        return this.props.doEnrollWithBeginProcess({devices})
            .then((response) => {
                if (response.status !== 'FAILED') {
                    return Promise.resolve(response);
                }
                else {
                    throw response.msg;
                }
            });
    };

    getDevices = () => {
        this.setState({
            devices: [],
            loading: true
        });

        this.props.registerPromise(
                bluetoothGetDevices()
            ).then(({devices}) => {
                if (!devices) {
                    return Promise.reject(t.bluetoothServiceError());
                }

                this.setState({loading: false});

                if (this.props.readonlyMode) {
                    return;
                }

                this.updateDeviceList(devices);
            }).catch((error) => {
                this.setState({loading: false});

                // Different error types: .msg (server), .message (JS exception), string (application threw)
                const message = error.msg || error.message || error;
                this.props.showStatus(message, STATUS_TYPE.ERROR);
            });
    };

    selectDevice(device) {
        let updatedDeviceList = [];
        const prevSelectedDevices = this.state.selectedDevices;
        if (prevSelectedDevices.some(selected => selected.hash === device.hash)) {
            updatedDeviceList = prevSelectedDevices.filter(selected => selected.hash !== device.hash);
        }
        else {
            // updatedDeviceList = updatedDeviceList.concat(prevSelectedDevices);
            updatedDeviceList.push(device);
        }
        this.setState({
            selectedDevices: updatedDeviceList,
            dataDirty: true
        });
    }

    updateDeviceList(devices) {
        if (devices.length) {
            this.props.showStatus(t.bluetoothSelectDevice(), STATUS_TYPE.INFO);
        }
        else {
            this.props.showStatus(t.bluetoothNoDevices(), STATUS_TYPE.ERROR);
            return;
        }

        // Update selected device info to match data received, if the same device is found
        let selectedDevices = this.state.selectedDevices;
        if (selectedDevices) {
            const selectedDeviceHashes = selectedDevices.map(d => d.hash);
            const updatedSelectedDevices = this.findDevicesWithHashList(devices, selectedDeviceHashes);
            if (updatedSelectedDevices) {
                selectedDevices = updatedSelectedDevices;
            }
        }

        // Add the enrolled device, if not found in data received.
        // If it is found in data received, mark it as enrolled; if no device is selected, select the enrolled device.
        const {data, isEnrolled} = this.props.template;
        if (isEnrolled) {
            const enrolledDeviceHashes = data.devices.map(d => d.hash);
            const detectedEnrolledDevices = this.findDevicesWithHashList(devices, enrolledDeviceHashes);
            const isEveryEnrolledDeviceAvailable = data.devices.length > 0 && data.devices.every(
                device => detectedEnrolledDevices.some(detected => detected.hash === device.hash)
            );

            if (!isEveryEnrolledDeviceAvailable) {
                const missingEnrolledDevices = data.devices.filter(
                    device => !detectedEnrolledDevices.some(detected => detected.hash === device.hash)
                );
                missingEnrolledDevices.forEach(missingDevice => devices.push({
                    ...missingDevice,
                    enrolled: true,
                    undetected: true
                }));
            }

            if (detectedEnrolledDevices.length > 0) {
                detectedEnrolledDevices.forEach(d => d.enrolled = true);
                if (!selectedDevices.length) {
                    selectedDevices = detectedEnrolledDevices;
                }
            }
        }

        // Update state
        const newState = {devices};
        if (selectedDevices) {
            newState.selectedDevices = selectedDevices;
        }
        this.setState(newState);
    }

    renderDeviceListItem(device) {
        const {hash, name, enrolled, undetected} = device;
        const {selectedDevices} = this.state;

        const tagName = name || t.unknownDevice();

        let iconName;
        let info;
        if (enrolled && undetected) {
            iconName = 'help_thick';
            info = t.bluetoothEnrolledUnconnected();
        }
        else {
            const deviceIsSelected = selectedDevices && (selectedDevices.some(d => d.hash === hash));
            if (deviceIsSelected) {
                iconName = 'status_ok_fill';
            }
            else {
                iconName = 'control_stop_thick';
            }
            info = enrolled ? t.bluetoothEnrolled() : null;
        }

        const canSelect = (!this.props.readonlyMode && !undetected);
        const onClick = canSelect ? () => this.selectDevice(device) : null;
        const tabIndex = canSelect ? '0' : null;
        const onKeyPress = canSelect ? (event) => {
            if (event.key === 'Enter' || event.key === ' ') {
                onClick();
            }
        } : null;

        const tagClass = 'ias-tag' + (!canSelect ? ' ias-disabled' : '');

        return (
            <div className={tagClass} key={hash} onClick={onClick} onKeyPress={onKeyPress} tabIndex={tabIndex}>
                <span className="ias-tag-content">
                    <i className={`ias-icon ias-icon-${iconName}`} />
                    <span>{tagName}</span>
                    <span>{info}</span>
                </span>
            </div>
        );
    }

    renderDeviceList() {
        const deviceElements = this.state.devices.map((device) => this.renderDeviceListItem(device));

        return (
            <div className="bluetooth-device-list">
                <div className="ias-tags">
                    {deviceElements}
                </div>
            </div>
        );
    }

    render() {
        const deviceList = this.renderDeviceList();
        let loadingIndicator = null;
        if (this.state.loading) {
            loadingIndicator = (
                <div className="bluetooth-loading-img">
                    <img
                        alt={t.loading()}
                        className="loading-img"
                        src={process.env.PUBLIC_URL + '/loading_anim_50.gif'}
                    />
                </div>
            );
        }

        return (
            <Authenticator
                description={t.bluetoothMethodDescription()}
                {...this.props}
            >
                {deviceList}
                {loadingIndicator}
                <button
                    className="ias-button"
                    disabled={this.state.loading}
                    id="Get_Devices_Button"
                    onClick={this.getDevices}
                    type="button"
                >
                    {t.bluetoothGetDevices()}
                </button>
            </Authenticator>
        );
    }
}

export default BluetoothMethod;
