/*
 * © Copyright 2014 – 2023 Open Text or one of its affiliates.
 *
 * The only warranties for products and services of Open Text and its affiliates and licensors ("Open Text") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Open Text shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

let toastEventsBeforeLoad = [];
let toastHandler = null;

export function createToast(toast) {
    if (toastHandler) {
        toastHandler(toast);
    }
    else {
        toastEventsBeforeLoad.push(toast);
    }
}

export function onCreateToast(handler) {
    toastHandler = handler;
    toastEventsBeforeLoad.forEach((toast) => {
        handler(toast);
    });
    toastEventsBeforeLoad = [];
}

export default createToast;
